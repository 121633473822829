import { mapGetters, mapMutations } from 'vuex';

export default {
	props: {
		sector: { type: String, required: true },
		abast: { type: String, required: true },
		required: { type: Boolean, default: false },
		valid: { type: Boolean, default: true },
	},
	data() {
		return { skip: false };
	},
	computed: {
		...mapGetters(['getEmissions', 'isReportEditable']),
		path() {
      return `${this.abast}.${this.$options.keyBlock}`;
    },
		emissions: {
			get() { return this.getEmissions(this.path); },
			set(emissions) { this.setEmissions({ path: this.path, emissions }); },
		},
		isValid() {
			return !this.required || this.skip || (this.emissions && !!this.emissions.length);
		},
		isSkipperVisible() { return this.required && (!this.emissions || !this.emissions.length); },
	},
	watch: {
		skip(isChecked) { this.emissions = isChecked ? null : []; },
		isValid: {
			immediate: true,
			handler(isValid) { this.$emit('update:valid', isValid); },
		},
	},
	methods: mapMutations(['setEmissions']),
	beforeMount() {
		if (!this.required) this.emissions = this.emissions || [];
		this.skip = this.emissions === null;
	},
};

import FUELS from './fuels';

export default [
	{
		label: 'Turisme',
		unit: 'turismes',
		collective: false,
		timed: false,
		passengers: true,
		emissions: {
			person: { emission: 0.119246, unit: 'persones' },
			fuels: [
				FUELS[0],
				FUELS[1],
				FUELS[2],
				FUELS[3],
				FUELS[4],
				FUELS[5],
				FUELS[6],
				FUELS[7],
				FUELS[8],
			],
			distance: [
				{ label: 'Gasoil', emission: 0.17887, unit: 'km' },
				{ label: 'Gasolina', emission: 0.18568, unit: 'km' },
				{ label: 'Elèctric', emission: 0.02743, unit: 'km' },
				{ label: 'Híbrid gasoil', emission: 0.11792, unit: 'km' },
				{ label: 'Hibrid gasolina', emission: 0.11792, unit: 'km' },
				{ label: 'Hibrid endollable', emission: 0.08579, unit: 'km' },
				{ label: 'Biodièsel B10', emission: 0.16816, unit: 'km' },
				{ label: 'Biodièsel B30', emission: 0.13082, unit: 'km' },
				{ label: 'Biodièsel B100', emission: 0, unit: 'km' },
			],
		},
	},
	{
		label: 'Furgoneta',
		unit: 'furgonetes',
		collective: false,
		timed: false,
		passengers: true,
		emissions: {
			person: { emission: 0.102996, unit: 'persones' },
			fuels: [FUELS[0], FUELS[1], FUELS[2], FUELS[6], FUELS[7], FUELS[8]],
			distance: [
				{ label: 'Gasoil', emission: 0.25749, unit: 'km' },
				{ label: 'Gasolina', emission: 0.26206, unit: 'km' },
				{ label: 'Elèctric', emission: 0.03085, unit: 'km' },
				{ label: 'Biodièsel B10', emission: 0.24208, unit: 'km' },
				{ label: 'Biodièsel B30', emission: 0.18833, unit: 'km' },
				{ label: 'Biodièsel B100', emission: 0, unit: 'km' },
			],
		},
	},
	{
		label: 'Camió',
		unit: 'camions',
		collective: false,
		timed: false,
		passengers: false,
		emissions: {
			fuels: [FUELS[0], FUELS[6], FUELS[7], FUELS[8]],
			distance: [
				{ label: 'Gasoil', emission: 0.97206, unit: 'km' },
				{ label: 'Biodièsel B10', emission: 0.91387, unit: 'km' },
				{ label: 'Biodièsel B30', emission: 0.71096, unit: 'km' },
				{ label: 'Biodièsel B100', emission: 0, unit: 'km' },
			],
		},
	},
	{
		label: 'Motocicleta',
		unit: 'motos',
		collective: false,
		timed: false,
		passengers: true,
		emissions: {
			person: { emission: 0.11662, unit: 'persones' },
			fuels: [FUELS[1]],
			distance: [
				{ label: 'Gasolina', emission: 0.11662, unit: 'km' },
			],
		},
	},
	{
		label: 'Autobús',
		unit: 'autobusos',
		collective: false,
		timed: false,
		passengers: true,
		emissions: {
			person: { emission: 0.10259, unit: 'persones' },
			fuels: [FUELS[0], FUELS[6], FUELS[7], FUELS[8]],
			distance: [
				{ label: 'Gasoil', emission: 1.53885, unit: 'km' },
				{ label: 'Biodièsel B10', emission: 1.44675, unit: 'km' },
				{ label: 'Biodièsel B30', emission: 1.12545, unit: 'km' },
				{ label: 'Biodièsel B100', emission: 0, unit: 'km' },
			],
		},
	},
	{
		label: 'Avió',
		unit: 'avions',
		collective: true,
		timed: false,
		passengers: true,
		emissions: {
			person: { emission: 0.18026, unit: 'persones' },
			fuels: [FUELS[9]],
			distance: [
				{ label: 'Querosè', emission: 0.18026, unit: 'km' },
			],
		},
	},
	{
		label: 'Tren',
		unit: 'trens',
		collective: true,
		timed: false,
		passengers: true,
		emissions: {
			person: { emission: 0.01225, unit: 'persones' },
			fuels: [FUELS[2]],
			distance: [
				{ label: 'Elèctric', emission: 0.01225, unit: 'km' },
			],
		},
	},
	{
		label: 'Moto de neu',
		unit: 'motos de neu',
		collective: false,
		timed: false,
		passengers: false,
		emissions: {
			fuels: [FUELS[1]],
			distance: [
				{ label: 'Gasolina', emission: 0.36894, unit: 'km' },
			],
		},
	},
	{
		label: 'Helicòpter',
		unit: 'helicòpters',
		collective: false,
		timed: true,
		passengers: true,
		emissions: {
			person: { emission: 968.743, unit: 'persones' },
			fuels: [FUELS[9]],
			distance: [
				{ label: 'Querosè', emission: 968.743, unit: 'hores' },
			],
		},
	},
	{
		label: 'Màquina trepitjaneu',
		unit: 'màquines trepitjaneu',
		collective: false,
		timed: true,
		passengers: false,
		emissions: {
			fuels: [FUELS[0], FUELS[6], FUELS[7], FUELS[8]],
			distance: [
				{ label: 'Gasoil', emission: 50.780, unit: 'hores' },
				{ label: 'Biodièsel B10', emission: 47.740, unit: 'hores' },
				{ label: 'Biodièsel B30', emission: 37.140, unit: 'hores' },
				{ label: 'Biodièsel B100', emission: 0, unit: 'hores' },
			],
		},
	},
	{
		label: 'Tractor',
		unit: 'tractors',
		collective: false,
		timed: true,
		passengers: false,
		emissions: {
			fuels: [FUELS[0]],
			distance: [
				{ label: 'Gasoil', emission: 31.62, unit: 'hores' },
			],
		},
	},
];

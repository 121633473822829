export default [
	{ label: 'Gasoil', emission: 2.55, unit: 'litres' },
	{ label: 'Gasolina', emission: 2.16, unit: 'litres' },
	{ label: 'Elèctric', emission: 0.124, losses: 0.0505, unit: 'kWh' },
	{ label: 'Híbird gasoil', emission: 2.55, unit: 'litres' },
	{ label: 'Híbrid gasolina', emission: 2.16, unit: 'litres' },
	{ label: 'Híbrid endollable', emission: 2.7, unit: 'litres' },
	{ label: 'Biodièsel B10', emission: 2.387, unit: 'litres' },
	{ label: 'Biodièsel B30', emission: 1.857, unit: 'litres' },
	{ label: 'Biodièsel B100', emission: 0, unit: 'litres' },
	{ label: 'Querosè', emission: 2.47, unit: 'litres' },
];

<template lang="html">
  <table :class="['emissions-table', { 'emissions-table--blocked': !editable } ]">
		<caption v-if="!editable && (!value || !value.length)">
			{{ $t('global.tip.skip') }}
		</caption>
		<thead v-else class="emissions-table__header">
			<tr>
				<slot name="header" />
				<th :colspan="editable ? 2 : 1">{{ $t('report.table.emissions') }}</th>
			</tr>
		</thead>
		<tbody class="emissions-table__body">
			<tr v-for="(item, i) in value" :key="i">
				<slot name="body" :item="item"></slot>
				<td>{{ item.emissions }}<em class="unit">kgCO₂</em></td>
				<td v-if="editable" class="emissions-table__actions">
					<button
						@click="deleteItem(i)"
						class="emissions-table__delete">
						&times;
					</button>
				</td>
			</tr>
		</tbody>
		<tfoot v-if="editable" class="emissions-table__footer">
			<tr class="emissions-table__form">
				<slot name="insert" :insert="insert"></slot>
				<td colspan="2" class="emissions-table__actions">
					<button
						@click="addItem"
						:disabled="!isValid"
						class="btn">
						{{ $t('global.button.add') }}
					</button>
				</td>
			</tr>
		</tfoot>
  </table>
</template>

<script>
export default {
	name: 'EmissionsTable',
	props: {
		value: { type: Array, required: true },
		editable: { type: Boolean, default: false },
	},
	data() {
		return { insert: { resource: {} } };
	},
	computed: {
		emissions() {
			const { mult = 1, resource, qty } = this.insert;
			const neatEmission = resource && resource.emission * (1 + (resource.losses || 0));
			return !!qty && Math.round(neatEmission * qty * mult * 100) / 100;
		},
		isValid() { return this.emissions !== false && !Number.isNaN(this.emissions); },
	},
	methods: {
		addItem() {
			// Clean objects to remove unwanted fields (keep only label, unit, timed & collective)
			const clean = Object.entries(this.insert).reduce((acc, [key, value]) => {
				const filter = ['vehicle', 'resource', 'group'].includes(key);
				const { label, unit, timed, collective } = value;
				acc[key] = filter ? { label, unit, timed, collective } : value;
				return acc;
			}, {});
			// Save & reset
			this.$emit('input', [...this.value, { ...clean, emissions: this.emissions }]);
			this.insert = { resource: {} };
		},
		deleteItem(index) {
			const items = this.value.filter((_, i) => i !== index);
			this.$emit('input', items);
		},
	},
};
</script>

<template lang="html">
	<section class="block">
		<h3>{{ $t(`${sector}.${abast}.mobils.title`) }}</h3>
		<p v-if="isReportEditable" class="note">{{ $t(`${sector}.${abast}.mobils.desc`) }}</p>
		<emissions-table v-if="!skip" v-model="emissions" :editable="isReportEditable">
			<template v-slot:header>
				<th>{{ $t('report.table.plate') }}</th>
				<th>{{ $t('report.table.method') }}</th>
				<th>{{ $t('report.table.vehicle') }}</th>
				<th>{{ $t('report.table.motorization') }}</th>
				<th>
					<span :data-tooltip-alert="$t('tooltip.qty_vary')">
						<i class="mdi mdi-alert-circle icon icon--emphasis" />
					</span>
					{{ $t('report.table.qty') }}
				</th>
			</template>
			<template v-slot:body="{ item }">
				<td>{{ item.concept }}</td>
				<td>{{ item.choice }}</td>
				<td>{{ item.vehicle.label }}</td>
				<td>{{ item.resource.label }}</td>
				<td>{{ item.qty }}<em class="unit">{{ item.resource.unit }}</em></td>
			</template>
			<template v-slot:insert="{ insert }">
				<td>
					<input
						type="text"
						v-model="insert.concept"
						:placeholder="$t('report.table.plate')" />
				</td>
				<td class="btn-group">
					<label>
						<input type="radio" v-model.number="insert.choice" value="1" />
						<span class="btn btn--outline">1</span>
					</label>
					<label>
						<input type="radio" v-model.number="insert.choice" value="2" />
						<span class="btn btn--outline">2</span>
					</label>
				</td>
				<td :data-tooltip="insert.choice ? false : $t('tooltip.require.method')">
					<dropdown-select
						v-model="insert.vehicle"
						:disabled="!insert.choice"
						:options="VEHICLES"
						:placeholder="$t('report.table.vehicle')" />
				</td>
				<td :data-tooltip="insert.vehicle ? false : $t('tooltip.require.vehicle')">
					<dropdown-select
						v-model="insert.resource"
						:disabled="!insert.vehicle"
						:options="insert.vehicle
							? insert.choice === 1
								? insert.vehicle.emissions.fuels
								: insert.vehicle.emissions.distance
							: []"
						:placeholder="$t('report.table.motorization')" />
				</td>
				<td :data-tooltip="isEmpty(insert.resource)
					? $t('tooltip.require.motorization')
					: $t('tooltip.unit_in', { unit: insert.resource.unit })">
					<input
						v-model.number="insert.qty"
						:disabled="isEmpty(insert.resource)"
						:placeholder="isEmpty(insert.resource)
							? $t('report.table.qty')
							: insert.resource.unit"
						type="number"
						min="0"
						step="0.1" />
				</td>
			</template>
		</emissions-table>
		<p v-if="isSkipperVisible">
			<label>
				<input type="checkbox" v-model="skip" :disabled="!isReportEditable" />
				{{ $t('global.tip.skip') }}
			</label>
		</p>
	</section>
</template>

<script>
import StepBlockMixin from '@/mixins/StepBlockMixin';
import EmissionsTable from '@/components/EmissionsTable.vue';
import DropdownSelect from '@/components/DropdownSelect.vue';
import { isEmpty } from '@/utils';
import VEHICLES from '@/assets/constants/vehicles';

const isNotCollective = (vehicle) => !vehicle.collective;

export default {
	name: 'StepMobils',
  keyBlock: 'mobils',
	mixins: [StepBlockMixin],
	components: { EmissionsTable, DropdownSelect },
	data() {
		return { VEHICLES: VEHICLES.filter(isNotCollective) };
	},
	methods: { isEmpty },
};
</script>
